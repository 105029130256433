/* @font-face {
  font-family: 'Satoshi-Regular';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: local(''),
       url('/fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
       url('/fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
       url('/fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
  }

@font-face {
  font-family: 'Satoshi-Medium';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: local('')
       url('/fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
       url('/fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
       url('/fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi-Bold';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: local(''),
       url('/fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
       url('/fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
       url('/fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
 */


@font-face {
  font-family: 'Satoshi-Variable';
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
  src:local(''),
      url('/fonts/Satoshi/Satoshi-Variable.woff2') format('woff2'),
      url('/fonts/Satoshi/Satoshi-Variable.woff') format('woff'),
      url('/fonts/Satoshi/Satoshi-Variable.ttf') format('truetype');
}
