.front-h1 {
  @apply font-sans font-light text-primary text-3xl md:text-4xl
}

.page-h1 {
  @apply text-accent text-4xl lg:text-5xl leading-tight pt-4 lg:pt-0
}

.page-h2 {
  @apply font-sans font-bold text-primary-darker text-2xl md:text-3xl
}

.page-h3 {
  @apply font-bold text-accent text-2xl leading-tight
}

.page-h4 {
  @apply font-bold text-primary-dark text-lg md:text-xl
}
