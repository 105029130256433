/********
 * Front-end CSS file
 ********/

@import "components/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h1, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.scroll-target:target:before {
  content: "";
  display: block;
  height: 90px;
  width: 100%;
  margin: -120px 0 0;
}

@media (min-width: 1280px) {
  .second-level-nav-item .second-level-nav-dd { display: none; }
  .second-level-nav-item:hover .second-level-nav-dd,
  .second-level-nav-item:focus .second-level-nav-dd,
  .second-level-nav-item:focus-within .second-level-nav-dd {
    display: flex;
  }
}

@import "components/buttons.css";
@import "components/headings.css";
@import "components/forms.css";
