.button {
  @apply inline-flex px-8 py-4 font-sans antialiased transition-all duration-300 leading-none tracking-wide justify-center text-center;
}

.btn-hero {
  @apply button inline-flex items-center text-white font-bold;
}

.btn-primary {
  @apply button bg-white border-2 border-accent rounded-md hover:rounded-br-[2rem] text-primary-darker antialiased tracking-wide font-bold uppercase hover:text-accent;
}

.btn-primary-solid {
  @apply button bg-accent-light border-2 border-accent-light rounded-md hover:rounded-br-[2rem] text-white antialiased tracking-wider font-bold uppercase hover:bg-accent;
}

.btn-secondary {
  @apply uppercase font-black tracking-wide text-primary-darker;
}

.nav-links {
  @apply cursor-pointer w-1/2 md:w-auto justify-center text-white h-8 items-center hover:text-secondary-light font-bold tracking-wide antialiased;
}

.post-btn {
  @apply h-0.5 w-7 bg-accent my-1 mr-2 ease-in rounded
}
