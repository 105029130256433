.input-wrapper {
    @apply flex items-center border border-gray-700 bg-white rounded-3xl h-12 transition ease-in-out focus-within:border-primary focus-within:border;
}

.form-input {
    @apply block w-full h-full px-2 py-4 xl:px-3 leading-none outline-2 outline-primary-light focus:text-primary-darker focus:outline-accent focus:invalid:outline-primary placeholder:text-primary-dark bg-white;
}

.text-input {
    @apply appearance-none form-input w-full;
}

.alert-success {
    @apply bg-[#dcedd9] border-[#cee6ca] border text-[#295521] shadow-sm;
}

.form-select {
    @apply appearance-none form-input bg-clip-padding bg-no-repeat mr-3 xl:mr-5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='green' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
    background-position: 100% center;
    background-size: 20px 20px;
}

.form-button {
    @apply px-8 py-2 uppercase rounded h-12 leading-none font-bold transition-all duration-500 flex items-center;
}